import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import getPurchaseStepName from 'utils/purchase/getPurchaseStepName';
import { formatPercent } from 'utils/Helpers';
import { digitalDiscountsTypes } from 'models/parseTrip';

/**
 * Converts passenger selection to a simplified format based on GrowthBook feature.
 * @param {Object} passengerSelection - The original passenger selection object.
 * @returns {Object} The simplified passenger selection object.
 */
const convertToSimple = (passengerSelection, typeCategory) => {
  if (typeCategory === 'simple') {
    const total = Object.values(passengerSelection).reduce((acc, value) => acc + value, 0);
    return { general: total };
  }
  return passengerSelection;
};
/**
 * Calculate the pricing for a trip.
 * @param {object} options - The options for calculating the pricing.
 * @param {object} options.pricing - The pricing information for the trip.
 * @param {number} options.passengersCount - The number of passengers.
 * @param {string} options.typeCategory - The category type for pricing calculation.
 * @param {object} options.passengerSelection - Contains the selected passengers in search.
 * @param {object} options.passengerTypes - Contains the pricing for each passenger type.
 * @returns {object} The calculated trip pricing.
 */
export const getTripPricing = ({
  pricing = {},
  passengersCount,
  passengerSelection = {},
  passengerTypes = [],
  typeCategory = 'categories',
}) => {
  if (passengerSelection.adult) {
    passengerSelection.general = passengerSelection.adult;
    delete passengerSelection.adult;
  }
  passengerSelection = convertToSimple(passengerSelection, typeCategory);

  const passengerAvailabilityOrder = passengerTypes.reduce(
    (acc, item) => {
      const key = item.type;

      // Check if the key exists in the acc object (accumulator) and the availability is lower
      if (acc[key] !== undefined && item.availability < acc[key]) {
        acc.general += acc[key] - item.availability;
        acc[key] = item.availability;
      }

      return acc;
    },
    { ...passengerSelection },
  );

  const generalCount = passengerAvailabilityOrder?.general || 0;
  const totalSelectionCount = Object.values(passengerAvailabilityOrder).reduce(
    (acc, val) => acc + val,
    0,
  );

  // Validate if the pricing has the single_trip_discount structure
  const hasSingleTripDiscount =
    pricing.providerDiscounts &&
    pricing.providerDiscounts.some(
      (discount) =>
        discount.name === 'single_trip_discount' &&
        'amount' in discount &&
        'taxes' in discount &&
        'total' in discount,
    );

  // Gets the normal price with digital discount or use the normal price
  const digitalDiscount =
    pricing.providerDiscounts &&
    pricing.providerDiscounts.find(
      (discount) =>
        digitalDiscountsTypes.includes(discount.name) || discount.name === 'single_trip_discount',
    );

  // Total with digital discount or normal total
  const normalTotal = digitalDiscount?.total || pricing.total;

  const totalPerGeneral = pricing.total || 0;
  const amountPerPassenger = pricing.amount || 0;
  const discountPerPassenger = pricing.providerDiscount?.amount || 0;
  const breakdown = pricing.breakdown || {};
  const luggagePerPassenger = breakdown.equipaje || 0;
  const networksPerPassenger = breakdown.redes || 0;
  const insurancePerPassenger = breakdown.seguros || 0;
  const taxesFromBreakdown = breakdown.impuestos || 0;
  const taxesFromPricing = pricing.taxes || 0;
  const taxesPerPassenger = taxesFromPricing > 0 ? taxesFromPricing : taxesFromBreakdown;

  // Calculate costs for general passengers
  const generalPassengerCount = Math.min(passengersCount, generalCount);
  const additionalPassengerCount = Math.max(passengersCount - totalSelectionCount, 0);
  const totalGeneralCount =
    passengerTypes.length <= 1 ? passengersCount : generalPassengerCount + additionalPassengerCount;

  let total =
    passengerTypes.length <= 1
      ? passengersCount * totalPerGeneral
      : totalPerGeneral * totalGeneralCount;
  let amount = amountPerPassenger * totalGeneralCount;
  const discount = discountPerPassenger * totalGeneralCount;

  // Calculate costs for other passenger types

  // Getting general total with early discount available
  const discountAvailability = pricing.discountAvailability ?? 5;
  const generalWitSpecialDiscountCount =
    totalGeneralCount <= discountAvailability ? totalGeneralCount : discountAvailability;

  const generalTotalWithDiscount = generalWitSpecialDiscountCount * totalPerGeneral;

  // Getting general total with early discount not available
  const generalWithoutDiscount =
    totalGeneralCount <= discountAvailability
      ? 0
      : totalGeneralCount - generalWitSpecialDiscountCount;
  const generalTotalWithoutDiscount =
    (generalWithoutDiscount > 0 && generalWithoutDiscount * normalTotal) || 0;

  if (passengerTypes.length >= 2) total = generalTotalWithDiscount + generalTotalWithoutDiscount;

  const passengerBreakdown = {
    general: {
      count: totalGeneralCount,
      total,
      totalSpecialDiscount: generalTotalWithDiscount,
      countWithSpecialDiscount: generalWitSpecialDiscountCount,
      countWithoutSpecialDiscount: generalWithoutDiscount,
      normalTotal: generalTotalWithoutDiscount,
      discount,
      discountPercent: !pricing.providerDiscount
        ? null
        : formatPercent(pricing.totalBeforeDiscount, pricing.total),
      normalDiscountPercent:
        passengerTypes.length >= 2 && generalTotalWithoutDiscount && digitalDiscount
          ? formatPercent(digitalDiscount.amount + digitalDiscount.taxes, normalTotal)
          : null,
    },
  };

  let remainingPassengersCount = passengersCount - totalGeneralCount;

  passengerTypes.forEach((passengerType) => {
    const { type, total: categoryPrice } = passengerType;
    if (type === 'general') {
      return;
    }
    const totalPerType = parseFloat(categoryPrice) || 0;
    const typeCount = Math.min(remainingPassengersCount, passengerAvailabilityOrder[type] || 0);
    total += totalPerType * typeCount;
    amount += totalPerType * typeCount;

    passengerBreakdown[type] = {
      count: typeCount,
      total: totalPerType * typeCount,
      discount: 0,
      discountPercent: formatPercent(pricing.totalBeforeDiscount, totalPerType),
    };
    remainingPassengersCount -= typeCount;
  });
  const totalPercentDiscount = pricing.totalBeforeDiscount
    ? formatPercent(pricing.totalBeforeDiscount, pricing.total)
    : 0;

  return {
    amount,
    total,
    totalPercentDiscount,
    totalBeforeDiscount: (pricing.totalBeforeDiscount || 0) * passengersCount,
    discount,
    discountType: pricing.discountType,
    passengersCount,
    luggage: luggagePerPassenger * passengersCount,
    taxes: taxesPerPassenger * passengersCount,
    networks: networksPerPassenger * passengersCount,
    insurance: insurancePerPassenger * passengersCount,
    passengerBreakdown,
    hasSingleTripDiscount,
    singleTripDiscount: hasSingleTripDiscount ? digitalDiscount : null,
  };
};

/**
 * Custom hook for calculating pricing before checkout.
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.isRoundTrip - The flag to indicate if the trip is a round trip.
 */
const usePricingBeforeCheckout = ({ isRoundTrip }) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const pricingBeforeCheckoutIsOn = features.PRICING_BEFORE_CHECKOUT;
  const departs = useSelector((state) => state.purchase.toJS().departs);
  const returns = useSelector((state) => state.purchase.toJS().returns);
  const location = useLocation();
  const purchaseStep = getPurchaseStepName(location.pathname);
  const isBeforeCheckout = ['dSeats', 'rSeats', 'passengers'].includes(purchaseStep);

  const passengersCount = departs?.selectedSeats?.length || 0;

  // When is a round trip the pricing key is different
  const departPricingPerPassenger = isRoundTrip
    ? departs?.fragments[0]?.departureRoundTripPricing
    : departs?.fragments[0]?.pricing;
  const departTripPricing = getTripPricing({ pricing: departPricingPerPassenger, passengersCount });

  // When is a round trip the pricing key is different
  const returnPricingPerPassenger = (returns?.fragments || [])[0]?.roundTripPricing;
  const returnTripPricing = returnPricingPerPassenger
    ? getTripPricing({ pricing: returnPricingPerPassenger, passengersCount })
    : { amount: 0, discount: 0, total: 0, luggage: 0, taxes: 0, networks: 0, insurance: 0 };

  return {
    passengersCount,
    displayPricingBeforeCheckout: isBeforeCheckout && pricingBeforeCheckoutIsOn,
    departTripPricing,
    returnTripPricing,
    purchaseTotal: departTripPricing.total + returnTripPricing.total,
  };
};

export default usePricingBeforeCheckout;
